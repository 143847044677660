import React from 'react';
import Image from '../assets/avatar.png'
import {FaLinkedin} from 'react-icons/fa'
import { TypeAnimation } from 'react-type-animation';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';

const Banner = () => {
    const onButtonClick = () => {
        // using Java Script method to get PDF file
        fetch('CH-CV.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'CH-CV.pdf';
                alink.click();
            })
        })
    }
    return (

        <section className='min-h-[85vh] lg:min-h-[78vh] flex items-center' id='home'>

            <div className='container mx-auto'>
                <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
                    <div className='flex-1 text-center font-secondary lg:text-left'>
                        <motion.h1
                        variants={fadeIn('up', 0.3)}
                        initial='hidden'
                        whileInView={'show'}
                        viewport={{one:false, amount: 0.7 }}
                        className='text-[55px] font-bold leading-[0.8] lg:text-[100px]'>
                            Cecile<span> Henry</span>
                        </motion.h1>
                    <motion.div
                        variants={fadeIn('up', 0.3)}
                        initial='hidden'
                        whileInView={'show'}
                        viewport={{one:false, amount: 0.7 }}
                    className='mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase
                    leading-[1]'>
                        <span className='text-white'></span>
                        <TypeAnimation sequence={[
                            'Developpeuse',
                            2000,
                            'Integratrice',
                            2000,
                        ]}
                        speed={50}
                        className='text-accent'
                        wrapper='span'
                        repeat={Infinity}
                        />
                    </motion.div>
                    <motion.p
                        variants={fadeIn('up', 0.3)}
                        initial='hidden'
                        whileInView={'show'}
                        viewport={{one:false, amount: 0.7 }}
                    className='mb-8 max-w-lg mx-auto lg:mx-0'>Mon expertise principale se situe dans le développement front end.
                    </motion.p>
                    <motion.div
                        variants={fadeIn('up', 0.6)}
                        initial='hidden'
                        whileInView={'show'}
                        viewport={{one:false, amount: 0.7 }}
                        className='flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0'>

                        <button className='btn btn-lg'><a href="tel:+33649435797">Contactez-moi</a></button>
                        <button className='text-gradient' onClick={onButtonClick}>
                    Télécharge mon CV
                </button>
                    </motion.div>
                    <div className='flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0'>
                        <a href="https://www.linkedin.com/in/cecile-henry/" target='_blank' rel="noreferrer">
                            <FaLinkedin />
                        </a>
                    </div>
                </div>
                <motion.div
                    variants={fadeIn('down', 0.5)}
                    initial='hidden'
                    whileInView={'show'}
                    viewport={{one:false, amount: 0.7 }}
                className='hidden  lg:flex flex-1 max-w-[320px] lg:max-w-[482px] mx-auto'>
                    <img className='border-2 border-white/50 rounded-xl main-image' src={Image} alt="" />
                </motion.div>
            </div>
        </div>
    </section>
    );
};

export default Banner;