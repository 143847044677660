import React from 'react';


const Header = () => {
    return <div className="py-8">
        <div className="container mx-auto">
            <div className='flex justify-between items-center'>
                <a href="/#">
                    <h2 className="text-gradient">Cec Henry</h2>
                </a>
                <button className='btn btn-sm'><a href="tel:+33649435797" without rel="noreferrer">Go !</a></button>
            </div>
        </div>
    </div>;
}

export default Header;