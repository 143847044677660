import React from 'react';

import {motion} from 'framer-motion'
import {fadeIn} from '../variants'

import Img1 from '../assets/portfolio-img1.png';
import Img2 from '../assets/portfolio-img2.png';
import Img3 from '../assets/portfolio-img3.png';
import Img4 from '../assets/portfolio-img4.png';

const Work = () => {
    return (
        <section className='section' id='work'>
            <div className="container mx-auto">
                <div className='flex flex-col lg:flex-row gap-x-10'>
                    <motion.div
                        variants={fadeIn('right', 0.3)}
                        initial='hidden'
                        whileInView={'show'}
                        viewport={{one:false, amount: 0.3 }}
                    className='flex-1 flex flex-col gap-y-12 mb-10 lg:mb-0'>
                        <div>
                            <h2 className="h2 leading-tight text-accent">Mes derniers<br /> Projets</h2>
                            <p className='max-w-sm mb-16'>Voici un extrait de mes réalisations et projets.

                            </p>
                            {/* <button className="btn btn-sm">Tous mes projets</button> */}
                        </div>
                        <div className=" group relative overflow-hidden border-2 border-white/50
                        rounded-xl">
                            <div className='bg-black/50 group-hover:bg-black/90 w-full h-64 absolute
                            z-40 transition-all duration-300'></div>
                            <img className='w-full group-hover:scale-125 h-64 transition-all duration-500'
                            src={Img1}
                            alt='Cali Europe site'
                            />
                            <div className='absolute -bottom-full left-12
                            group-hover:bottom-24 transition-all duration-500 z-50'>
                                <span className='text-gradient'>Cali Europe</span>
                                </div>

                            <div className='absolute -bottom-full left-12
                            group-hover:bottom-14 transition-all duration-700 z-50'>
                                <a href="https://www.cali-europe.com/fr" rel="noreferrer" target='_blank' className='white'>Voir plus</a>
                                </div>

                        </div>
                        <div className="group relative overflow-hidden border-2 border-white/50
                        rounded-xl">
                            <div className='bg-black/40 group-hover:bg-black/90 w-full h-64 absolute
                            z-40 transition-all duration-300'></div>
                            <img className='w-full group-hover:scale-125 transition-all h-64 duration-500'
                            src={Img4}
                            alt='Banque stellantis finance'
                            />
                            <div className='absolute -bottom-full left-12
                            group-hover:bottom-24 transition-all duration-500 z-50'>
                                <span className='text-gradient'>Banque stellantis finance</span>
                                </div>

                            <div className='absolute -bottom-full left-12
                            group-hover:bottom-14 transition-all duration-700 z-50'>
                                <a href="https://www.banque-stellantis-france.com/fr" rel="noreferrer" target='_blank' className='white'>Voir plus</a>
                                </div>

                        </div>
                    </motion.div>

                    <motion.div
                        variants={fadeIn('left', 0.2)}
                        initial='hidden'
                        whileInView={'show'}
                        viewport={{one:false, amount: 0.3 }}

                    className='flex-1 flex flex-col gap-y-10'>
                    <div className="group relative overflow-hidden border-2 border-white/50
                        rounded-xl">
                            <div className='bg-black/40 group-hover:bg-black/90 w-full h-64 absolute
                            z-40 transition-all duration-300'></div>
                            <img className='w-full group-hover:scale-125 h-64 transition-all duration-500'
                            src={Img2}
                            alt='Banque PSA finance'
                            />
                            <div className='absolute -bottom-full left-12
                            group-hover:bottom-24 transition-all duration-500 z-50'>
                                <span className='text-gradient'>Banque PSA finance</span>
                                </div>

                            <div className='absolute -bottom-full left-12
                            group-hover:bottom-14 transition-all duration-700 z-50'>
                                <a href="https://www.stellantisfinancialservices.com/fr" rel="noreferrer" target='_blank' className='white'>Voir plus</a>
                                </div>
                        </div>
                        <div className="group relative overflow-hidden border-2 border-white/50
                        rounded-xl">
                            <div className='bg-black/40 group-hover:bg-black/90 w-full h-64 absolute
                            z-40 transition-all duration-300'></div>
                            <img className='w-full group-hover:scale-125 transition-all h-64 duration-500'
                            src={Img3}
                            alt='Finance stellantis finance'
                            />
                            <div className='absolute -bottom-full left-12
                            group-hover:bottom-24 transition-all duration-500 z-50'>
                                <span className='text-gradient'>Finance stellantis finance</span>
                                </div>

                            <div className='absolute -bottom-full left-12
                            group-hover:bottom-14 transition-all duration-700 z-50'>
                                <a href="https://www.stellantis-finance-services.fr/" rel="noreferrer" target='_blank' className='white'>Voir plus</a>
                                </div>

                        </div>

                        <p>Je suis également intervenue sur d'autres projets pour le développement de nouvelles fonctionnalités,
                            de maintenance. <br/>
                            <span><a href="https://www.tango.lu/fr" target='_blank' rel="noreferrer"  className=' flex text-gradient text-[20px] tracking-wider '>Tango </a></span>
                            <span><a href="https://www.ebrc.com/fr" target='_blank' rel="noreferrer" className='  flex text-gradient text-[20px] tracking-wider '>EBRC </a></span>
                            <span><a href="https://merignac.com/" target='_blank' rel="noreferrer" className='  flex text-gradient text-[20px] tracking-wider '>Merignac </a></span>

                            <span><a href="https://www.klima-agence.lu/fr" target='_blank' rel="noreferrer" className='  flex text-gradient text-[20px] tracking-wider '>Klima agence... </a></span>


                        </p>
                    </motion.div>
                </div>
            </div>
        </section>
    );
}

export default Work;