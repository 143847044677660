import React from 'react';

import { motion } from 'framer-motion';
import { fadeIn } from '../variants'

const services = [
    {
        name: 'Languages',
        description: 'CSS, SCSS/SASS, Bootstrap, HTML, Javascript, Jquery, PHP',
        // link: 'learn more'
    },
    {
        name: 'CMS et librairies',
        description: 'Drupal, Wordpress, React',
    },
    {
        name: 'Outils Front end',
        description: 'NPM, Wepback, Gulp',
    },
    {
        name: 'Environnement de travail',
        description: 'Docksal, PHPStorm, Gitlab, figma',
    },
    {
        name: 'Organisation',
        description: 'Outils Atlassian: JIRA, confluence',
        link: 'learn more'
    }

]

const Services = () => {
    return (
        <section className='section' id='services'>
            <div className="container mx-auto">
                <div className='flex flex-col lg:flex-row'>
                    <motion.div
                         variants={fadeIn('right', 0.3)}
                         initial='hidden'
                         whileInView={'show'}
                         viewport={{one:false, amount: 0.3 }}

                    className='flex-1 lg:bg-bottom bg-no-repeat mix-blend-lighten mb-12
                    lg:mb-0'>
                        <h2 className='h2 text-accent mb-6'>Ce que je connais.</h2>
                        <h3 className='h3 max-w-[455px] mb-16'>Développeuse depuis environ 3 ans</h3>
                        <p>Passionnée par la création de solutions informatiques, je veille à fournir
                        un travail de qualité.
                        </p><br />
                        <p>J'ai eu l'ocasion de travailler sur des projets de toutes tailles, tout en prenant
                            en compte la mise en place des bonnes pratiques <strong>SEO</strong>.
                        </p><br />
                        <p>J'ai également acquis une expérience dans la gestion de projet en travaillant en étroite
                            collaboration avec les membres de l'équipe pour planifier, prioriser et livrer des fonctionnalités
                            dans les délais impartis.</p>
                        {/* <button className='btn btn-sm'>See my work</button> */}
                    </motion.div>

                    <motion.div
                         variants={fadeIn('left', 0.3)}
                         initial='hidden'
                         whileInView={'show'}
                         viewport={{one:false, amount: 0.3 }}

                    className='flex-1 lg:ml-24'>
                        <div className=''>
                            {services.map((service, index) => {
                                const {name, description} = service;
                                return (
                                    <div className='border-b border-white/20 h-[100px]
                                    mb-[28px] flex' key={index}>
                                        <div>
                                            <h4 className='text-gradient text-[20px] tracking-wider font-primary
                                            font-semibold mb-6'>{name}</h4>
                                            <p className='font-secondary leading-tight'>
                                                {description}
                                            </p>
                                        </div>
                                        {/* <div className='flex flex-col flex-1 items-end'>
                                            <a href='#'
                                            className='btn w-9 h-9 mb-[42px] flex justify-center
                                            items-center'
                                            >
                                                <BsArrowUpRight />
                                            </a>
                                            <a href='#' className='text-gradient text-sm'>{link}</a>
                                            </div>*/}
                                    </div>
                                )
                            })}
                        </div>
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default Services;