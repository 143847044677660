import React from 'react';

import CountUp from 'react-countup';

import { useInView } from 'react-intersection-observer';
import { motion} from 'framer-motion';
import {fadeIn } from '../variants';

const About = () => {
    const [ref, inView] = useInView({
        threshold: 0.5,
    });
    return (
    <section className='flex bottom-2 lg:bottom-8 w-full overflow-hidden z-50' id='about' ref={ref}>
        <div className='container mx-auto'>
            <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center
            lg:gap-x-20 lg:gap-y-0 h-screen'>

                <motion.div
                                    variants={fadeIn('right', 0.3)}
                                    initial='hidden'
                                    whileInView={'show'}
                                    viewport={{once:false, amount: 0.3}}
                className='flex-1 bg-about bg-contain bg-no-repeat h-[340px]
                mix-blend-lighten bg-top'>
                </motion.div>
                <motion.div
                                    variants={fadeIn('left', 0.5)}
                                    initial='hidden'
                                    whileInView={'show'}
                                    viewport={{once:false, amount: 0.3}}
                className='flex-1'>
                    <h2 className='h2 text-accent'>A propos de moi</h2>
                    <h3 className='h3 mb-4'>Je suis développeuse Web.</h3>
                    <p className='mb-6'>En constante recherche de nouveaux défis.
                    </p>
                    <div className='flex gap-x-6 lg:gap-x-10 mb-12'>
                        <div>
                            <div className='text-[40px] pt-1 font-tertiary text-gradient mb-2'>
                                {
                                    inView ?
                                    <CountUp start={0} end={10} duration={3} /> : null}
                                    +
                            </div>
                            <div className='font-primay text-sm tracking-[2px]'>Projets <br /> réalisés
                            </div>
                        </div>

                        <div>
                            <div className='text-[40px] pt-1 font-tertiary text-gradient mb-2'>
                                {
                                    inView ?
                                    <CountUp start={0} end={3} duration={3} /> : null}
                                    +
                            </div>
                            <div className='font-primay text-sm tracking-[2px]'>Années<br /> d'xpériences
                            </div>
                        </div>

                        <div>
                            <div className='text-[40px] pt-1 font-tertiary text-gradient mb-2'>
                                {
                                    inView ?
                                    <CountUp start={0} end={20} duration={3} /> : null}
                                    +
                            </div>
                            <div className='font-primay text-sm tracking-[2px]'>Clients <br /> satisfaits
                            </div>
                        </div>

                    </div>
                    {/* <div className='flex mt-12 gap-x-8 items-center'>
                        <button className='btn btn-lg'>Contact me</button>
                        <a href="#" className='text-gradient btn-link'>
                            My Portfolio
                        </a>
                    </div> */}
                </motion.div>
            </div>
        </div>
    </section>
    );
};

export default About;