import {useState, useEffect} from 'react'
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';

const Contact = () => {

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: ""
    })

    const handleChange = e => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const [errors, setErrors] = useState({})
    const validate = (formData) => {
        let formErrors = {}
        if(!formData.name){
            formErrors.name = "Name required"
        }
        if(!formData.email){
            formErrors.email = "Email required"
        }
        if(!formData.message){
            formErrors.message = "Message is required"
        }
        return formErrors
    }

    const [isSubmitted, setIsSubmitted] = useState(false)

    const handleSubmit = e => {
        setErrors(validate(formData))
        setIsSubmitted(true)
        e.preventDefault();

    }

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
      }

    useEffect(() => {
        if(Object.keys(errors).length === 0 && isSubmitted){

            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({ "form-name": "contact-form", ...formData })
            })
            .then(() => alert("Success!"))
            .then(() => setIsSubmitted(false))
            .then(() => setFormData({name: "", email: "",  message: ""}))
            .catch(error => alert(error))
        }
    }, [errors, formData, isSubmitted])

  return (
    <section className="py-16 lg:selection" id="contact">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          <motion.div
            variants={fadeIn('left', 0.3)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ one: false, amount: 0.3 }}
            className="flex-1 flex justify-start items-center"
          >
            <div>
              <h4 className="text-xl uppercase text-accent font-medium mb-2 tracking-wide">
                Gardons contact !
              </h4>
              <h2 className="text-[45px] lg:text-[90px] leading-none mb-12">
                Allons plus <br />loin ensemble
              </h2>
            </div>
          </motion.div>
          <motion.form
            onSubmit={handleSubmit}
            variants={fadeIn('left', 0.3)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ one: false, amount: 0.3 }}
            className="flex-1 border rounded-2xl flex flex-col gap-y-6 pb-24 p-6 items-start"
          >
            <input
              name="name"
              value={formData.name}
               onChange={handleChange}
              className="bg-transparent border-b py-3
                    outline-none w-full placeholder:text-white focus:border-accent
                    transition-all"
              type="text"
              placeholder="Votre prénom"
            />

            <input
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="bg-transparent border-b py-3
                    outline-none w-full placeholder:text-white focus:border-accent
                    transition-all"
              type="text"
              placeholder="Votre email"
            />

            <textarea
value={formData.message} onChange={handleChange}
{...errors.message && (<p>{errors.message}</p>)}
              name="message"
              className="bg-transparent border-b py-12 outline-none w-full
                    placeholder:text-white focus:border-accent transition-all resize-none mb-12"
              placeholder="Votre message"
            ></textarea>
            <button type="submit" className="btn btn-lg">
              Envoyer le message
            </button>
          </motion.form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
